import Navbar from "../../component/navbar/Navbar";
import ViewTitle from "../../component/title/view/ViewTitle";
import Title from "../../component/title/Title";
import Text from "../../component/text/Text";
import Footer from "../../component/footer/Footer";

export default function NotFoundView() {
    return <div className={"not-found-view"}>
        <Navbar/>
        <ViewTitle>
            <Title>Nejsi tu omylem?</Title>
            <Text>Tato stránka bohužel neexistuje</Text>
        </ViewTitle>
        <Footer/>
    </div>
}