import "./Button.css"
import {createRef, MouseEventHandler} from "react"

export default function Button(props: {
    type?: "primary" | "secondary" | "green" | "red"
    loading?: boolean
    disabled?: boolean
    placeholder?: boolean
    children?: any
    onClick?: MouseEventHandler<HTMLDivElement>
}) {
    const {loading, disabled, placeholder, children, onClick} = props;
    const type = props.type ?? "primary";

    const ref = createRef<HTMLDivElement>()
    const width = loading ? (ref.current === null ? undefined : ref.current.clientWidth + "px") : undefined;

    return <div style={{
        width: width
    }}
                ref={ref}
                onClick={onClick}
                className={`btn btn-${type}${loading ? " loading" : ""}${disabled ? " disabled" : ""}${placeholder ? " placeholder" : ""}`}>
        {loading ? <div className={"loader"}/> : (placeholder ? "-" : children)}
    </div>


}