import "./Text.css"
import {createContext, useContext } from "react";

const TextContext = createContext<number>(0);

export default function Text(props: {
    children?: any
    bold?: boolean
    highlighted?: boolean
    spacing?: boolean
    placeholder?: boolean
    color?: "red" | "green"
    className?: string
}) {
    const { children, bold, highlighted, spacing, placeholder, color, className } = props;
    const context = useContext(TextContext);
    
    const classes = `text${bold ? " bold" : ""}${highlighted ? " highlighted" : ""}${spacing ? " spacing" : ""}${placeholder ? " placeholder" : ""}${color ? ` ${color}` : ""}${className ? ` ${className}` : ""}`;
    
    const content = <TextContext.Provider value={context + 1}>
        {children}
    </TextContext.Provider>;
    
    if (context <= 0) return <p className={classes}>{content}</p>
    return <span className={classes}>{content}</span>
}