import "./Payment.css"
import Currency, {formatCurrency} from "../../../store/currency/Currency";
import {PaymentRecord} from "../PaymentList"
import Text from "../../../../component/text/Text";
import {formatCzechPlural} from "../../../../util/lang";
import {IconChevronDown, IconGift} from "@tabler/icons-react";
import {useEffect, useState} from "react";
import Button from "../../../../component/button/Button";
import fetchWithRetry from "../../../../util/request";
import {Link} from "react-router-dom";

function formatDate(time: number) {
    const date = new Date(time * 1000);

    return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
}

function formatItemPreview(items: string[]) {
    if (items.length <= 1) return items.join(", ");

    const visible = items.slice(0, 2);

    if (visible.length < items.length) {
        const more = items.length - visible.length;
        return `${visible.join(", ")} a ${more >= 5 ? "dalších" : "další"} ${more} ${formatCzechPlural(more, "předmět", "předměty", "předmětů")}`;
    }

    return visible.slice(0, visible.length - 1).join(", ") + " a " + visible[visible.length - 1];
}

function formatState(payment: PaymentRecord): string {
    switch (payment.state) {
        case "COMPLETED":
            return "Zaplaceno";
        case "PENDING":
            return "Čeká na vyřízení";
        case "CANCELED":
            return "Zrušeno";
        case "REFUNDED":
            return "Vráceno";
    }
}

export default function PaymentComponent(props: {
    payment: PaymentRecord
    currencies: { [key: string]: Currency }
    token: string
}) {
    const {payment, currencies, token} = props;
    const currency = currencies[payment.currency];

    const [expanded, setExpaned] = useState(false);
    const [paymentLink, setPaymentLink] = useState<string | null>(null);

    useEffect(() => {
        if (payment.state !== "PENDING") return;
        return fetchWithRetry(res => {
            if (res.status === 401) return true;
            if (!res.ok) return false;

            return res.text().then(body => {
                if (body.length > 0)
                    setPaymentLink(body);

                return true;
            })
        }, process.env.REACT_APP_API_BASE + "/store/pay/" + payment.uuid, {
            headers: {
                Authorization: token,
                "Target-Hostname": window.location.origin
            }
        })
    }, [setPaymentLink, token, payment])

    if (!currency) return <Text>Neplatná měna platby, kontaktujte podporu</Text>

    currency.multiplied = payment.currency_multiplied;

    return <div className={`payment ${payment.state.toLowerCase()}${expanded ? " expanded" : ""}`}>
        <div className={"fields"} onClick={(e) => {
            let checkEle: HTMLElement = e.target as HTMLElement;

            while (checkEle.parentElement) {
                if (checkEle.classList.contains("btn")) return;
                checkEle = checkEle.parentElement;
            }

            setExpaned((!expanded));
        }}>
            <p className={"date"}>{formatDate(payment.date)}</p>
            <p className={"item-preview"}>{formatItemPreview(payment.items.map(v => v.item))}</p>
            {paymentLink !== null ?
                <Link to={paymentLink}
                      target={"_blank"}><Button>Zaplatit {formatCurrency(currency, payment.total)}</Button></Link> :
                <p className={"total"}>{formatCurrency(currency, payment.total)}</p>
            }
            <IconChevronDown className={"expand-icon"}/>
        </div>
        {!expanded ? null : <div className={"content"}>
            <p className={"state"}>{formatState(payment)}</p>
            <div className={"items"}>
                {payment.items.map((item, i) =>
                    <div key={i} className={"item"}>
                        <p className={"name"}>{item.item}
                            {item.gift ?
                                <span className={"gift-tag"}>
                                <IconGift/>
                                <span className={"user"}>{item.gift_name ?? "Unknown"}</span>
                            </span>
                                : null}</p>
                        <p className={"price"}>{formatCurrency(currency, item.price)}</p>
                    </div>
                )}
            </div>
        </div>}
    </div>
}