export type CancelFunction = () => void;

export default function fetchWithRetry(
    callback: (res: Response) => boolean | Promise<Boolean>,
    input: RequestInfo | URL,
    init?: RequestInit,
    interval: number = 5000
): CancelFunction {
    let timeout: NodeJS.Timeout | undefined;
    let cancelled = false;

    function cancel() {
        cancelled = true;
        if (timeout) clearTimeout(timeout);
    }

    function makeRequest() {
        if (cancelled) return;
        fetch(input, init).then(res => {
            if (!cancelled) return callback(res);
            return true;
        }).then(v => {
            if (!v) timeout = setTimeout(makeRequest, interval);
            else cancel();
        }).catch(() => setTimeout(makeRequest, interval))
    }

    makeRequest();

    return cancel;
}