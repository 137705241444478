import "./SideNavWidget.css"

export default function SideNavWidget(props: {
    title: string
    className?: string
    children?: any
}) {
    const {title, className, children} = props;

    return <div className={`store-widget${className ? ` ${className}` : ""}`}>
        <h3>{title}</h3>
        <div className={"content"}>
            {children}
        </div>
    </div>
}