import "./PaymentStatusView.css"
import {useEffect, useMemo, useState} from "react";
import Footer from "../../../component/footer/Footer";
import Navbar from "../../../component/navbar/Navbar";
import {IconAlertCircle, IconCircleCheck, IconCircleX, IconReceiptRefund} from "@tabler/icons-react";
import fetchWithRetry from "../../../util/request";
import {useNavigate, useParams} from "react-router-dom";
import {PaymentRecord} from "../../account/payments/PaymentList";

export default function PaymentStatusView() {
    const navigate = useNavigate();
    const query = useMemo(() => new URLSearchParams(window.location.search), []);
    let {paywall} = useParams();

    const [payment, setPayment] =
        useState<PaymentRecord | null | undefined>(undefined)
    let [smsProperties, setSmsProperties] = useState<null | {
        phoneNumber: string
        price: string
    }>()

    useEffect(() => {
        const paymentId = query.get("id");

        if (paymentId === null) {
            setPayment(null);
            return;
        }

        return fetchWithRetry(async res => {
            if (res.status === 404) {
                setPayment(null);
                navigate("/store")
                return true;
            }
            if (!res.ok) return false;
            setSmsProperties({
                phoneNumber: res.headers.get("SMS-Payment-Number") ?? "",
                price: decodeURI(res.headers.get("SMS-Payment-Price") ?? "").replaceAll("+", " ")
            })
            return res.json().then(body => {
                const payment = body as PaymentRecord;
                setPayment(payment);
                return payment.state !== "PENDING";
            })
        }, `${process.env.REACT_APP_API_BASE}/store/payment/${paywall ? `${paywall}/` : ""}${paymentId}/state`, {}, 1500);
    }, [query, paywall, setPayment, navigate])

    const state = payment?.state;

    let content;

    if (state === "COMPLETED") {
        content = <div className={"status green"}>
            <IconCircleCheck className={"icon"}/>
            <h2>Děkujeme za nákup</h2>
            <p className={"description"}>Předměty budou doručeny do 12 hodin, běžně však za několik sekund</p>
        </div>
    } else if (state === "PENDING") {
        content = <div className={"status"}>
            <div className={"loader icon"}/>
            <h2>{payment?.sms_code ? "Čekáme na platbu" : "Platba se zpracovává"}</h2>
            {!payment?.sms_code ? null :
                <p className={"sms-info"}>Zaplatíte pomocí odeslání SMS ve tvaru <a
                    href={`sms:${smsProperties?.phoneNumber}?&body=${encodeURI(`PTC ${payment?.sms_code}`)}`}>PTC {payment?.sms_code}</a> na
                    telefonní
                    číslo <span>{smsProperties?.phoneNumber}</span>. Cena SMS
                    je <span>{smsProperties?.price}</span> včetně DPH</p>}
        </div>
    } else if (state === "CANCELED") {
        content = <div className={"status red"}>
            <IconCircleX className={"icon"}/>
            <h2>Platba byla zrušena</h2>
        </div>
    } else if (state === "REFUNDED") {
        content = <div className={"status red"}>
            <IconReceiptRefund className={"icon"}/>
            <h2>Platba byla refundována</h2>
        </div>
    } else if (state === null) {
        content = <div className={"status red"}>
            <IconAlertCircle className={"icon"}/>
            <h2>Platba nebyla nalezena</h2>
        </div>
    } else {
        content = <div className={"loader big center margin"}/>;
    }

    return <div className={"payment-status-view"}>
        <Navbar/>
        {content}
        <Footer/>
    </div>
}