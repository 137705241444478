import "./SwitchInput.css"

export default function SwitchInput(props: {
    label: any,
    defaultValue: boolean,
    callback: (value: boolean) => void
}) {
    const {label, defaultValue, callback} = props;
    return <div className={"switch-input"}>
        {label ? <p className={"label"}>{label}</p> : null}
        <label>
            <input type={"checkbox"} defaultChecked={defaultValue} onChange={e => {
                callback(e.target.checked);
            }}/>
            <span className={"slider"}/>
        </label>
    </div>
}