import {IconGift, IconX} from "@tabler/icons-react";
import {createRef, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {addCartItem, GemPayment, GiftUser, StoreCategory, StoreItem, useCurrency} from "../../../../app/store/store";
import Button from "../../../../component/button/Button";
import useOutsideHandler from "../../../../util/OutsideHandler";
import Currency, {formatCurrency} from "../../currency/Currency";
import "./StoreItemModal.css"
import {useAuth} from "../../../../app/auth/auth";
import {useNavigate} from "react-router-dom";

export default function StoreItemModal(props: {
    item: StoreItem
    category: StoreCategory
    currencies: { [key: string]: Currency }
    close: () => void
    setGemPayment: (v: GemPayment) => void
}) {
    const {item, category, currencies, close, setGemPayment} = props;

    const dispatch = useAppDispatch();
    const currencyName = useAppSelector(useCurrency);
    const user = useAppSelector(useAuth).user;
    const navigate = useNavigate()

    const currency: Currency | undefined = currencies[currencyName];

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.setProperty("overflow", null);
        }
    })

    const [gift, setGift] = useState(false);
    const [giftProfile, setGiftProfile] = useState<GiftUser | null>(null);

    const ref = createRef<HTMLDivElement>()

    useOutsideHandler(ref, close);

    return <div className={"store-item-modal-container"}>
        <IconX className={"close"} onClick={close}/>
        <div className={"store-item-modal"} ref={ref}>
            <div className={"properties"}>
                <h3>{item.name}</h3>
                {item.bonus ? <p className={"bonus"}>{item.bonus}</p> : null}
                {item.description ? <p className={"description"}>{item.description}</p> : null}
                {item.benefits ? <div className={"benefits"}>
                    <h4>Výhody</h4>
                    {item.benefits.parent ? <p key={"parent"} className={"inherit"}>Obsahuje vše z <span
                        className={"parent"}>{item.benefits.parent}</span></p> : null}
                    {item.benefits.sections.map((section, index) => {
                        return <div className={"section"} key={index}>
                            <h5>{section.label}</h5>
                            {section.items.map((benefit, index) => {
                                return <div className={"benefit"} key={"benefit-" + index}>{benefit}</div>
                            })}
                        </div>;
                    })}
                </div> : null}
            </div>
            <div className={"item"}>
                <img src={item.asset} alt={""}/>
                <p className={"price"}>
                    {formatCurrency(currency, item.price[currencyName])}{item.price["GEMS"] ?
                    <span className={"gems"}>{formatCurrency(currencies["GEMS"], item.price["GEMS"])}</span> : null}
                </p>
                {item.duration ? <p className={"duration"}>Za {item.duration.value} {item.duration.unit}</p> : null}
                <div className={"button-container"}>
                    <div className={`gift${gift ? " active" : ""}`}>
                        <Button type={"red"} onClick={() => {
                            setGift(!gift);
                        }}><IconGift/>Darovat</Button>
                        <input
                            spellCheck={false}
                            type={"text"}
                            className={!giftProfile ? "error" : undefined}
                            placeholder={"Nickname"}
                            onBlur={e => {
                                const name = e.target.value;
                                const nameLength = name.length;
                                if ((nameLength > 2 && nameLength <= 16 && /^\w{3,16}$/i.test(name)) || nameLength === 36 || nameLength === 32) {
                                    fetch(process.env.REACT_APP_API_BASE + `/user/${name}/profile`)
                                        .then(res => {
                                            if (!res.ok) {
                                                setGiftProfile(null);
                                            } else {
                                                res.json().then(body => {
                                                    const user = body as GiftUser;
                                                    if (e.target.value !== name) return;
                                                    e.target.value = user.name;
                                                    setGiftProfile(user);
                                                })
                                            }
                                        })
                                } else {
                                    setGiftProfile(null);
                                }
                            }}/>
                    </div>
                    <div className={"buy"}>
                        <Button onClick={() => {
                            if (gift && !giftProfile) return;
                            let giftUuid = gift ? giftProfile?.uuid : undefined;
                            if (user?.uuid === giftUuid) giftUuid = undefined;
                            dispatch(addCartItem({
                                category: category.id,
                                id: item.id,
                                gift: giftUuid
                            }))
                            close();
                        }}>Koupit</Button>
                        {item.price["GEMS"] ? <Button type={"green"} onClick={() => {
                            if (!user) {
                                navigate("/login?return=/store/" + category.id);
                            } else {
                                if (gift && !giftProfile) return;
                                setGemPayment({
                                    item: item,
                                    category: category.id,
                                    gift: gift && giftProfile?.uuid !== user.uuid ? {
                                        uuid: giftProfile?.uuid ?? "",
                                        name: giftProfile?.name ?? ""
                                    } : undefined
                                })
                                close();
                            }
                        }}>Koupit za gemy</Button> : null}
                    </div>
                </div>
            </div>
        </div>
    </div>

}