import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import {store} from './app/store';
import {loadCartItems} from "./app/store/store";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

store.dispatch(loadCartItems())