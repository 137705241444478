import {configureStore} from '@reduxjs/toolkit'
import authReducer from "./auth/auth";
import storeReducer from "./store/store";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        store: storeReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch