import "./GameMode.css"

export default function GameMode(props: {
    id: string
    name: string
    description: string
}) {
    const { id, name, description } = props;
    
    return <div className={"gamemode"}>
        <div className={"image"}>
            <img src={`/img/gamemode/${id}.webp`} alt={""}/>
        </div>
        <div className={"content"}>
            <p className={"name"}>{name}</p>
            <p className={"description"}>{description}</p>
        </div>
    </div>
}