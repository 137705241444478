import "./SideNavbar.css"
import {Link} from "react-router-dom";
import {Icon} from "../icon/Icon";

export default function SideNavBar(props: {
    title: any
    entries: {
        icon: string
        name: string
        link: string
        selected?: boolean
    }[]
    children?: any
    className?: string
}) {
    const {title, entries, children, className} = props;

    return <div className={`side-navbar${className ? ` ${className}` : ""}`}>
        <div className={"navbar-title"}>{title}</div>
        <div className={"entries"}>
            {entries.map(v => <Link to={v.link} key={v.link} className={v.selected ? "selected" : undefined}>
                <Icon iconName={v.icon}/>{v.name}
            </Link>)}
        </div>
        <div className={"widgets"}>{children}</div>
    </div>
}