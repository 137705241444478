import "./HomeView.css"
import gamemodes from "./gamemodes/types.json";
import HomeTitle from "./title/HomeTitle";
import React, {useEffect, useState} from "react";
import Text from "../../component/text/Text";
import GameMode from "./gamemodes/GameMode";
import Footer from "../../component/footer/Footer";
import FlexBox from "../../component/flexbox/FlexBox";
import fetchWithRetry from "../../util/request";
import Navbar from "../../component/navbar/Navbar";

export default function HomeView() {
    const [playerStats, setPlayerStats] = useState<{
        online?: number,
        registered?: number
    }>({});

    useEffect(() => {
        const cancelRegisterFetch = fetchWithRetry(async res => {
            let body = await res.text();
            if (!res.ok) return false;
            setPlayerStats(prevState => {
                const state: any = {};
                Object.assign(state, prevState);
                const count = parseInt(body);
                if (!isNaN(count))
                    state.registered = count;
                return state;
            })
            return true;
        }, process.env.REACT_APP_API_BASE + "/stats/players/registered");

        const cancelOnlineFetch = fetchWithRetry(async res => {
            return res.json().then(body => {
                if (!res.ok) return false;
                if (!body.online) {
                    setPlayerStats(prevState => {
                        const state: any = {};
                        Object.assign(state, prevState);
                        state.online = 0;
                        return state;
                    })
                } else setPlayerStats(prevState => {
                    const state: any = {};
                    Object.assign(state, prevState);
                    state.online = body.players.online;
                    return state;
                })
                return true;
            })
        }, "https://api.mcsrvstat.us/2/mc.ptero-cloud.net")
        return () => {
            cancelRegisterFetch();
            cancelOnlineFetch();
        }
    }, [])

    return <div className={"home-view"}>
        <Navbar/>
        <HomeTitle/>
        <Text bold={true} spacing={true}>
            Na serveru momentálně hraje <Text highlighted={true} placeholder={playerStats.online === undefined}>{playerStats.online ?? "lo"}</Text> z <Text highlighted={true} placeholder={playerStats.registered === undefined}>{playerStats.registered ?? "lorem"}</Text> registrovaných hráčů.
        </Text>
        <FlexBox justifyContent={"center"} className={"max-width-app"}>
            {gamemodes.map(gamemode => <GameMode key={gamemode.id} id={gamemode.id} name={gamemode.name}
                                                 description={gamemode.desc}/>)}
        </FlexBox>
        <Footer/>
    </div>
}