import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../store";
import fetchWithRetry from "../../util/request";

const DEFAULT_CURRENCY = "CZK";

export interface StoreItem {
    id: string
    name: string
    description?: string
    asset: string
    asset_style: string
    price: { [key: string]: number }
    bonus?: string
    duration?: {
        value: number
        unit: "hours" | "days" | "month"
    }
    benefits?: {
        parent?: string
        sections: {
            label: string
            items: string[]
        }[]
    }
    featured: string[]
}

export interface StoreCategory {
    id: string
    name: string
    icon: string
    description?: string
    featured_label?: string
}

export interface GiftUser {
    uuid: string
    name: string
    premid: string
}

export interface CartItem {
    category: string
    id: string
    gift?: string
    giftUser?: GiftUser
    store?: StoreItem
}

export interface StoreCart {
    items: CartItem[]
}

export interface GemPayment {
    item: StoreItem,
    category: string,
    gift?: {
        uuid: string,
        name: string
    }
}

function removeDuplicates<T>(arr: T[]) {
    return arr.reduce((acc, curr) => {
        if (!acc.includes(curr))
            acc.push(curr);
        return acc;
    }, [] as T[]);
}

export const addCartItems = createAsyncThunk(
    'store/addCartItems',
    async (items: CartItem[]) => {
        return await loadStoreItems(items)
    }
)

export const loadCartItems = createAsyncThunk(
    'store/loadCartItems',
    async () => {
        const cartRaw = JSON.parse(window.localStorage.getItem("pt-store-cart") ?? "[]") as { [key: string]: string }[]

        const cartPersistent: CartItem[] = cartRaw.map(o => {
            return {
                category: o.category,
                id: o.id,
                gift: o.gift
            }
        });

        return await loadStoreItems(cartPersistent)
    }
)

function loadStoreItems(items: CartItem[]): Promise<CartItem[]> {
    return new Promise((resolve) => {
        fetchWithRetry(async res => {
            return res.json().then((body: { [key: string]: StoreItem | GiftUser }) => {
                if (!res.ok) return false;
                resolve(
                    items.map(item => {
                        return {
                            id: item.id,
                            category: item.category,
                            gift: item.gift,
                            giftUser: item.gift ? (body[item.gift] as GiftUser) : undefined,
                            store: body[`${item.category}/${item.id}`] as StoreItem
                        }
                    })
                )
                return true;
            })
        }, process.env.REACT_APP_API_BASE + "/store/cart?" +
            removeDuplicates(items.map(({category, id}) => `item=${category}/${id}`))
                .concat(
                    removeDuplicates(items.filter(({gift}) => !!gift).map(({gift}) => `user=${gift}`)).join("&")
                )
                .join("&")
        );
    })
}

export const addCartItem = createAsyncThunk(
    'store/addCartItem',
    async (item: CartItem) => {
        return (await loadStoreItems([item]))[0];
    }
)

interface StoreState {
    currency: string
    cart: StoreCart
}

function createInitialState(): StoreState {
    return {
        currency: window.localStorage.getItem(("pt-store-currency")) ?? DEFAULT_CURRENCY,
        cart: {
            items: []
        }
    }
}

const initialState: StoreState = createInitialState();

function saveCart(items: CartItem[]) {
    window.localStorage.setItem("pt-store-cart", JSON.stringify(items.map((v: {
        [key: string]: any
    }) => {
        return {
            category: v.category,
            id: v.id,
            gift: v.gift
        }
    })))
}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        changeCurrency: (state, action: PayloadAction<string>) => {
            state.currency = action.payload;
            window.localStorage.setItem("pt-store-currency", action.payload);
        },
        removeCartItem: (state, action: PayloadAction<number>) => {
            state.cart.items.splice(action.payload, 1);
            saveCart(state.cart.items);
        },
        clearCart: (state) => {
            state.cart.items = []
            saveCart(state.cart.items);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addCartItems.fulfilled, (state, action) => {
            state.cart.items.push(...(action.payload as CartItem[]));
            saveCart(state.cart.items);
        })
        builder.addCase(loadCartItems.fulfilled, (state, action) => {
            state.cart.items.push(...(action.payload as CartItem[]));
        })
        builder.addCase(addCartItem.fulfilled, (state, action) => {
            state.cart.items.push(action.payload);
            saveCart(state.cart.items);
        })
    }
})

export const {changeCurrency, removeCartItem, clearCart} = storeSlice.actions

export const useStore = (state: RootState) => state.store
export const useCurrency = (state: RootState) => state.store.currency
export const useCart = (state: RootState) => state.store.cart

export default storeSlice.reducer