import "./HomeTitle.css"
import {Link} from "react-router-dom";
import Button from "../../../component/button/Button";
import ViewTitle from "../../../component/title/view/ViewTitle";
import FlexBox from "../../../component/flexbox/FlexBox";
import Title from "../../../component/title/Title";

export default function HomeTitle() {
    return <ViewTitle>
        <FlexBox justifyContent={"space-between"} alignItems={"center"} gap={20}>
            <div className={"content"}>
                <Title main={true}>Ptero-Cloud</Title>
                <p className={"description"}>
                    Vítej na našich oficiálních webových stránkách. Nezapomeň se připojit k našemu Discordu a podívat se
                    na další sociální sítě. Těšíme se, až tě uvidíme!
                </p>
                <a href={"https://discord.ptero-cloud.net"} target="_blank" rel="noreferrer">
                    <Button>Připoj se na Discord</Button>
                </a>
                <Link to={"/store"}><Button type="secondary">Obchod</Button></Link>
            </div>
            <img src="/img/logo/large.webp" className="logo" alt={""}/>
        </FlexBox>
    </ViewTitle>
}