import "./CustomerOfMonth.css"
import {useEffect, useState} from "react"
import {useAppSelector} from "../../../../app/hooks"
import {useCurrency} from "../../../../app/store/store"
import SideNavWidget from "../../../../component/sidenavbar/widget/SideNavWidget"
import fetchWithRetry from "../../../../util/request"
import Currency, {formatCurrency} from "../../currency/Currency"
import "./CustomerOfMonth.css"

interface Customer {
    name: string
    uuid: string
    premid: string
    gem_value: number
}


export default function CustomerOfMonth(props: {
    currencies: { [key: string]: Currency }
}) {
    const {currencies} = props;

    const currencyName = useAppSelector(useCurrency)
    const [data, setData] = useState<Customer | null>();

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (res.status === 204) return true;
            if (!res.ok) return false;
            let body = (await res.json()) as Customer;
            setData(body);
            return true;
        }, process.env.REACT_APP_API_BASE + "/store/top/customer");
    }, [])

    if (!data) return null;

    const currency = currencies[currencyName];

    return <SideNavWidget title={"Zákazník měsíce"}>
        <div className={"customer-of-month"}>
            <img src={"https://minotar.net/helm/" + (data.premid !== null ? data.premid : data.uuid) + "/100.png"}
                 onError={e => {
                     e.currentTarget.style.display = "none";
                 }}
                 alt={" "}/>
            <div className={"details"}>
                <p className={"name"}>{data.name}</p>
                <p className={"value"}>{formatCurrency(currency,
                    Math.round(data.gem_value * currency.multiplied / currency.gem_value)
                )}</p>
            </div>
        </div>
    </SideNavWidget>
}