import "./DiscordConnection.css"
import {TokenData} from "../../../app/auth/auth";
import fetchWithRetry from "../../../util/request";
import {useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import Button from "../../../component/button/Button";

interface DiscordUser {
    id: string
    username: string
    global_name: string
    avatar: string
    premium_type: number
}

function linkAccount(token: string, code: string, callback: (value: DiscordUser | string) => void) {
    fetch(process.env.REACT_APP_API_BASE + "/user/discord", {
        method: "POST",
        headers: {
            Authorization: token,
            "Redirect-Uri": window.location.origin + window.location.pathname
        },
        body: code
    }).then(async res => {
        return res.json().then(body => {
            if (res.ok) {
                callback(body as DiscordUser);
                return true;
            } else {
                callback(body.message as string);
                return false;
            }
        })
    })
}


export default function DiscordConnection(props: {
    token: string
    user: TokenData
    url: string
}) {
    const {token, url} = props;
    const linkCode = useMemo(() => new URLSearchParams(window.location.search).get("code"), []);
    const isOnPopup = useMemo(() => window.opener && window.opener !== window, []);

    const [discordConnection, setDiscordConnection] = useState<DiscordUser | null | undefined>();
    const [loading, setLoading] = useState(false);
    const [linkError, setLinkError] = useState<string | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (linkCode) {
            if (isOnPopup) {
                window.opener.dispatchEvent(new CustomEvent("discordlinked", {
                    detail: linkCode
                }))
                window.close()
            } else {
                setLoading(true)
                linkAccount(token, linkCode, res => {
                    setLoading(false);
                    if (typeof res === "string") {
                        setLinkError(res)
                    } else {
                        setLinkError(null)
                        setDiscordConnection(res)
                    }
                });
            }
            return;
        } else return fetchWithRetry(res => {
            if (res.status === 401) {
                navigate("/login?return=" + url)
                return true;
            }

            if (res.status === 204) {
                setDiscordConnection(null);
                return true;
            }

            if (!res.ok) return false;

            return res.json().then((body: DiscordUser) => {
                setDiscordConnection(body);
                return true;
            })
        }, process.env.REACT_APP_API_BASE + "/user/discord", {
            headers: {
                Authorization: token
            }
        })
    }, [isOnPopup, linkCode, navigate, token, url])

    useEffect(() => {
        const handler = (e: Event) => {
            setLoading(true);
            linkAccount(token, (e as CustomEvent).detail, res => {
                if (typeof res === "string") {
                    setLinkError(res)
                } else {
                    setLinkError(null)
                    setDiscordConnection(res)
                }
                setLoading(false)
            })
        }
        window.addEventListener("discordlinked", handler)
        return () => window.removeEventListener("discordlinked", handler);
    })

    if (isOnPopup) {
        return null;
    }

    if (discordConnection === undefined)
        return <div className={"loader big center margin"}/>

    if (discordConnection === null)
        return <div className={"connect-account"}>
            <h3>Nemáš propojený účet</h3>
            <p>Pokud si svůj Discord připojíš, budeš se moct rychleji přihlašovat a na našem discord serveru získáš
                roli podle svého VIP.</p>
            <Button loading={loading} type={"secondary"} onClick={() => {
                setLoading(true);

                fetch(process.env.REACT_APP_API_BASE + "/user/discord/authurl",
                    {
                        headers: {
                            "Return-Hostname": window.location.origin
                        }
                    })
                    .then(res => res.text())
                    .then(res => {
                        setLoading(false);
                        const popup = window.open(res, "pterocloud_discord_link",
                            "resizable=no,location=no,toolbar=no,menubar=no,width=500,height=700");
                        if (!popup) window.location.href = res;
                    })
                    .catch(() => setLoading(false));
            }}>Propojit účet</Button>
            {linkError ? <p className={"error"}>{linkError}</p> : null}
        </div>
    return <div className={`connected-account tier-${discordConnection.premium_type}`}>
        <div className={"user"}>
            <img
                className={"avatar"}
                src={`https://cdn.discordapp.com/avatars/${discordConnection.id}/${discordConnection.avatar}` +
                    (discordConnection.avatar.startsWith("a_") ? ".gif" : ".webp") + "?size=256"}
                alt={"Avatar uživatele " + discordConnection.global_name}/>
            <p className={"display-name"}>{discordConnection.global_name}</p>
            <p className={"username"}>{discordConnection.username}</p>
        </div>
        <div className={"details"}>
            <div className={"info"}>
                <h3>Tvůj účet je propojený</h3>
                <p>Nyní se můžeš rychleji přihlašovat a na našem Discord serveru si získal roli dle tvého VIP.</p>
            </div>
            <Button type={"secondary"} loading={loading} onClick={() => {
                if (loading) return;
                setLoading(true);
                fetch(process.env.REACT_APP_API_BASE + "/user/discord", {
                    method: "DELETE",
                    headers: {
                        Authorization: token
                    }
                }).then(res => {
                    setLinkError(null)
                    setLoading(false);
                    if (res.ok) {
                        setDiscordConnection(null);
                    }
                }).then(() => setLoading(false))
            }}>Odebrat propojení</Button>
        </div>
    </div>;
}