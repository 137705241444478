import "./DocumentView.css"
import czechVOP from "./files/vop.md";
import englishRules from "./files/rules.md";
import czechRules from "./files/pravidla.md";
import gdpr from "./files/gdpr.md";
import Navbar from "../../component/navbar/Navbar";
import NotFoundView from "../notfound/NotFoundView";
import Document from "../../component/document/Document";
import Footer from "../../component/footer/Footer";
import {useEffect, useState} from "react";
import fetchWithRetry from "../../util/request";
import ViewTitle from "../../component/title/view/ViewTitle";
import Title from "../../component/title/Title";

export const DOCUMENTS: {
    [key: string]: {
        title: string,
        path: string
    }
} = {
    vop: {
        title: "Všeobecné obchodní podmínky",
        path: czechVOP
    },
    rules: {
        title: "Rules",
        path: englishRules
    },
    pravidla: {
        title: "Pravidla",
        path: czechRules
    },
    gdpr: {
        title: "Zásady o ochraně osobních údajů",
        path: gdpr
    }
}

export default function DocumentView(props: {
    documentName: string
}) {
    const document = DOCUMENTS[props.documentName];

    const [documentText, setDocumentText] = useState<string | null>(null);

    useEffect(() => {
        if (!document.path) return;

        return fetchWithRetry(res => {
            if (!res.ok) return false;
            res.text().then(text => setDocumentText(text));
            return true;
        }, document.path)
    })

    if (!document)
        return <NotFoundView/>;

    return <div className={"document-view"}>
        <Navbar/>
        <ViewTitle>
            <Title>{document.title}</Title>
        </ViewTitle>
        {documentText === null ? <div className={"loader big center margin"}/> : <Document>{documentText}</Document>}
        <Footer/>
    </div>
}