import "./CurrencySelector.css"
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../app/hooks";
import {changeCurrency, useCurrency} from "../../../../app/store/store";
import Currency from "../../currency/Currency";
import {IconCaretDownFilled} from "@tabler/icons-react";
import {createRef, useState} from "react";
import useOutsideHandler from "../../../../util/OutsideHandler";

export default function CurrencySelector(props: {
    currencies: { [key: string]: Currency }
}) {
    const {currencies} = props;

    const dispatch = useDispatch();
    const currency = useAppSelector(useCurrency);

    const [expanded, setExpanded] = useState(false);
    const ref = createRef<HTMLDivElement>()

    useOutsideHandler(ref, () => setExpanded(false));

    return <div onClick={() => {
        setExpanded(!expanded);
    }} ref={ref} className={`currency-selector${expanded ? " expanded" : ""}`}>
        {currency}
        <IconCaretDownFilled/>
        <div className={"other"}>
            {
                Object.entries(currencies).filter((entry: [string, Currency]) => entry[0] !== currency && entry[0] !== "GEMS").map((entry: [string, Currency]) =>
                    <p className={"currency"} key={entry[0]}
                       onClick={() => dispatch(changeCurrency(entry[0]))}>{entry[0]}</p>)
            }
        </div>
    </div>
}