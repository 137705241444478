import {RefObject, useEffect} from "react";

export default function useOutsideHandler(ref: RefObject<HTMLElement>, handler: (e: MouseEvent) => void) {
    useEffect(() => {
        function handleClickOutside(ev: Event) {
            const mouseEv = ev as MouseEvent;
            const target = mouseEv.target as HTMLElement;
            if (ref.current && !ref.current.contains(target))
                handler(mouseEv);
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handler, ref]);
}