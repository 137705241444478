import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import HomeView from "./view/home/HomeView";
import {Provider} from "react-redux";
import {store} from "./app/store";
import LoginView from "./view/login/LoginView";
import StoreView from './view/store/StoreView';
import NotFoundView from "./view/notfound/NotFoundView";
import {useEffect, useLayoutEffect} from 'react';
import StoreCheckoutView from './view/store/checkout/StoreCheckoutView';
import PaymentStatusView from "./view/store/payment/PaymentStatusView";
import AccountView from "./view/account/AccountView";
import DocumentView, {DOCUMENTS} from "./view/document/DocumentView";

const Wrapper = ({children}: { children: any }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export default function App() {
    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE + "/stats/visit").catch(console.log);
    })

    return <Provider store={store}>
        <BrowserRouter>
            <Wrapper>
                <Routes>
                    <Route path={"/"} element={<HomeView/>}/>
                    <Route path={"/login"} element={<LoginView/>}/>
                    <Route path={"/store/payment/:paywall?"} element={<PaymentStatusView/>}/>
                    <Route path={"/store/checkout"} element={<StoreCheckoutView/>}/>
                    <Route path={"/store/:category?"} element={<StoreView/>}/>
                    <Route path={"/account/:section"} element={<AccountView/>}/>
                    {Object.keys(DOCUMENTS).map(key => {
                        return <Route path={key} key={key} element={<DocumentView documentName={key} key={key}/>}/>
                    })}
                    <Route path={"/*"} element={<NotFoundView/>}/>
                </Routes>
            </Wrapper>
        </BrowserRouter>
    </Provider>
}