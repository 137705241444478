import {createRef, RefObject, useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {removeCartItem, StoreCart, useCart, useCurrency} from "../../../app/store/store";
import Footer from "../../../component/footer/Footer";
import Navbar from "../../../component/navbar/Navbar";
import fetchWithRetry from "../../../util/request";
import Currency, {formatCurrency} from "../currency/Currency";
import "./StoreCheckoutView.css";
import Title from "../../../component/title/Title";
import {IconGift, IconX} from "@tabler/icons-react";
import SwitchInput from "../../../component/switch/SwitchInput";
import Button from "../../../component/button/Button";
import {useAuth} from "../../../app/auth/auth";
import {Link, useNavigate} from "react-router-dom";
import Text from "../../../component/text/Text";
import {Icon} from "../../../component/icon/Icon";

interface InputOptions {
    id: string
    label: string
    regex?: RegExp
    defaultValue?: string
    inputRef?: RefObject<HTMLInputElement>
}

const INVOICE_INPUTS: InputOptions[] = [
    {
        id: "fullname",
        label: "Jméno a příjmení",
        regex: /^\S+(\s\S+)*$/
    }, {
        id: "street",
        label: "Ulice",
        regex: /^.+$/
    }, {
        id: "city",
        label: "Obec",
        regex: /^.+$/
    }, {
        id: "postalcode",
        label: "PSČ",
        regex: /^((\d{5})|(\d{3} \d{2}))$/
    }, {
        id: "country",
        label: "Země",
        defaultValue: "Česká republika",
        regex: /^.+$/
    }
]

interface PaywallInfo {
    name: string
    id: string
    paywall: string
    icon: string
    enabled: boolean
    reason: string
}

function InputField(props: InputOptions) {
    const {id, label, regex, defaultValue, inputRef} = props;

    let valid = true;
    const currentRef = inputRef?.current;
    if (currentRef && regex && currentRef.value.length > 0) {
        valid = regex.test(currentRef.value);
    }

    return <div className={`input-field${valid ? "" : " invalid"}`}>
        <label htmlFor={id} className={"label"}>{label}</label>
        <input type={"text"} name={id} defaultValue={defaultValue ?? ""}
               ref={inputRef} onInput={(e) => {
            const value = e.currentTarget.value;
            if (regex && !regex.test(value)) {
                e.currentTarget.classList.add("invalid")
            } else {
                e.currentTarget.classList.remove("invalid")
            }
        }}/>
    </div>
}

export default function StoreCheckoutView() {
    const currencyName = useAppSelector(useCurrency)
    const cart: StoreCart = useAppSelector(useCart)
    const total = cart.items.map(i => i.store?.price).filter(i => i !== undefined).map(i => (i as {
        [key: string]: number
    })[currencyName]).reduce((a, b) => a + b, 0);
    const dispatch = useAppDispatch()
    const userToken = useAppSelector(useAuth).token
    const user = useAppSelector(useAuth).user

    const navigate = useNavigate()

    const [currencies, setCurrencies] = useState<{ [key: string]: Currency } | null>(null)

    const currency: Currency | undefined = currencies?.[currencyName]

    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const [invoice, setInvoice] = useState(false)
    const invoiceInputs = useMemo(() => INVOICE_INPUTS.map(input => {
        return {
            id: input.id,
            label: input.label,
            regex: input.regex,
            defaultValue: input.defaultValue,
            inputRef: createRef<HTMLInputElement>()
        }
    }), [])
    const emailInput = {
        id: "email",
        label: "Email",
        regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        inputRef: createRef<HTMLInputElement>()
    }
    const [vop, setVop] = useState(false)

    const [paymentOptions, setPaymentOptions] = useState<PaywallInfo[] | null>(null)
    const [selectedPaymentOption, setSelectedPaymentOption] =
        useState<null | {
            id: string
            paywall: string
        }>(null)

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (!res.ok) return false;
            let body = (await res.json()) as { [key: string]: Currency };
            setCurrencies(body)
            return true;
        }, process.env.REACT_APP_API_BASE + "/store/currencies");
    }, [setCurrencies])

    useEffect(() => {
        if (!user) navigate("/login?return=/store/checkout")
    }, [user, navigate])

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (!res.ok) {
                setPaymentOptions(null)
                return false;
            }
            let body = (await res.json()) as PaywallInfo[]
            setPaymentOptions(body)
            return true;
        }, `${process.env.REACT_APP_API_BASE}/store/paywall/list/${currencyName}/${total}`);
    }, [setPaymentOptions, currencyName, total])

    const [sending, setSending] = useState(false);

    function sendForm() {
        if (!vop) {
            setErrorMessage("Musíte souhlasit se Všeobecnými obchodními podmínkami")
            return;
        }
        if (!selectedPaymentOption?.paywall) {
            setErrorMessage("Musíte zvolit platební metodu");
            return;
        }
        setErrorMessage(undefined)
        if (sending) return;
        const inputs: InputOptions[] = [emailInput];
        if (invoice) inputs.push(...invoiceInputs);
        const form: { [key: string]: any } = {
            paywall: selectedPaymentOption.paywall
        }
        let invalid = false;
        for (let input of inputs) {
            const currentRef = input.inputRef?.current;
            if (input.regex && currentRef) {
                if (input.regex && !input.regex.test(currentRef.value)) {
                    invalid = true;
                    currentRef.classList.add("invalid")
                } else {
                    form[input.id] = currentRef.value ?? "";
                    currentRef.classList.remove("invalid")
                }
            }
        }
        if (invalid) return;
        form.invoice = invoice;

        const token = userToken;
        if (!token) return;

        setSending(true)

        const body = {
            items: cart.items.map(item => {
                return {
                    id: item.id,
                    category: item.category,
                    gift: item.gift ?? undefined
                }
            }),
            currency: currencyName,
            form: form
        };

        fetch(process.env.REACT_APP_API_BASE + "/store/checkout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
                "Return-Hostname": window.location.origin
            },
            body: JSON.stringify(body)
        }).then(res => {
            setSending(false)
            if (res.ok) {
                res.text().then(url => {
                    window.localStorage.removeItem("pt-store-cart");
                    window.location.href = url;
                })
            } else {
                res.json().then(body => {
                    setErrorMessage(body.message);
                })
            }
        })
    }

    const invoiceRequired = (currency?.invoice_required_amount ?? total + 1) <= total

    if (invoiceRequired && !invoice) setInvoice(true)

    return <div className={"store-checkout-view"}>
        <Navbar/>
        {!currency ? <div className={"loader big center margin"}/> : <div className={"checkout"}>
            <Title>Shrnutí nákupu</Title>
            <div className={"items"}>
                {
                    cart.items.map((item, index) => {
                        if (!item.store) return null;
                        return <div className={"item"} key={index}>
                            <p className={"name"}>
                                <span className={"item-name"}>
                                    {item.store.name}
                                </span>
                                {item.giftUser ? (
                                    <span className={"gift-tag"}>
                                        <IconGift/>
                                        <span className={"user"}>{item.giftUser.name}</span>
                                    </span>
                                ) : null}
                            </p>
                            <p className={"price"}>{formatCurrency(currency, item.store.price[currencyName])}</p>
                            <IconX size={30} className={"remove"} onClick={() => {
                                dispatch(removeCartItem(index))
                                if (cart.items.length === 1) {
                                    navigate("/store");
                                }
                                setPaymentOptions(null)
                            }}/>
                        </div>
                    })
                }
            </div>
            <div className={"summary"}>
                <p className={"label"}>Celkem</p>
                <p className={"total"}>{formatCurrency(currency, total)}</p>
            </div>
            <div className={"form"} onKeyDown={e => {
                if (e.code === "Enter") sendForm()
            }}>
                <InputField {...emailInput}/>
                {invoiceRequired ? null :
                    <SwitchInput label={"Chci fakturu"} defaultValue={invoice} callback={setInvoice}/>}
                <div className={"invoice-requirements"} style={{
                    display: invoice ? undefined : "none"
                }}>
                    {invoiceInputs.map(input => <InputField key={input.id} {...input}/>)}
                </div>
                <SwitchInput label={<>Souhlasím se <Link to={"/vop"} target={"_blank"}>Všeobecnými obchodními
                    podmínkami</Link></>} defaultValue={vop} callback={setVop}/>
            </div>
            <div className={"payment-options"}>
                <p className={"label"}>Platební metoda</p>
                {paymentOptions ? <div className={"paywall-list"}>
                    {paymentOptions.map(paywall => <div
                        key={paywall.id}
                        className={`paywall ${paywall.enabled ? "enabled" : "disabled"}${paywall.id === selectedPaymentOption?.id ? " selected" : ""}`}
                        onClick={(e) => {
                            if (paywall.enabled) {
                                setSelectedPaymentOption({
                                    id: paywall.id,
                                    paywall: paywall.paywall
                                })
                            }
                        }}>
                        <Icon iconName={paywall.icon}/>
                        <div className={"info"}>
                            <p className={"name"}>{paywall.name}</p>
                            {paywall.reason ? <p className={"reason"}>{paywall.reason}</p> : null}
                        </div>
                    </div>)}
                </div> : <div className={"loader center margin"}/>}
            </div>
            {errorMessage ? <Text color={"red"}>{errorMessage}</Text> : null}
            <Button onClick={sendForm} loading={sending}>Pokračovat k platbě</Button>
        </div>}
        <Footer/>
    </div>
}