import "./StoreNavbar.css";
import Currency from "../currency/Currency";
import SideNavBar from "../../../component/sidenavbar/SideNavbar";
import CurrencySelector from "./currency/CurrencySelector";
import {StoreCategory} from "../../../app/store/store";
import Cart from "./cart/Cart";
import CustomerOfMonth from "./customerofmonth/CustomerOfMonth";

export default function StoreNavbar(props: {
    currency: string
    currencies: { [key: string]: Currency }
    category: string
    categories: StoreCategory[]
    children?: any
}) {
    const {currencies, category, categories} = props;

    return <SideNavBar className={"store-navbar"} title={<>Obchod<CurrencySelector currencies={currencies}/></>}
                       entries={categories.map(c => {
                           return {
                               icon: c.icon,
                               name: c.name,
                               link: `/store/${c.id}`,
                               selected: c.id.toLowerCase() === category.toLowerCase()
                           }
                       })}>
        <Cart currencies={currencies}/>
        <CustomerOfMonth currencies={currencies}/>
    </SideNavBar>
}