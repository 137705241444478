import {removeCartItem, StoreCart, useCart, useCurrency} from "../../../../app/store/store"
import "./Cart.css"
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import SideNavWidget from "../../../../component/sidenavbar/widget/SideNavWidget";
import Button from "../../../../component/button/Button";
import {IconGift, IconX} from "@tabler/icons-react";
import Currency, {formatCurrency} from "../../currency/Currency";
import {useNavigate} from "react-router-dom";

export default function Cart(props: {
    currencies: { [key: string]: Currency }
}) {
    const cart: StoreCart = useAppSelector(useCart);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const currency = useAppSelector(useCurrency);

    if (cart.items.length <= 0) return null;

    return <SideNavWidget className={"cart"} title={"Nákupní košík"}>
        <div className={"items"}>
            {
                cart.items.map((item, index) => {
                    if (!item.store) return null;
                    return <div className={"item"} key={index}>
                        <p className={"name"}>
                                <span className={"item-name"}>
                                    {item.store.name}
                                </span>
                            {item.giftUser ? (
                                <span className={"gift-tag"}>
                                        <IconGift/>
                                        <span className={"user"}>{item.giftUser.name}</span>
                                    </span>
                            ) : null}
                        </p>
                        <p className={"price"}>{formatCurrency(props.currencies[currency], item.store.price[currency])}</p>
                        <IconX className={"remove"} onClick={() => {
                            dispatch(removeCartItem(index))
                        }}/>
                    </div>
                })
            }
        </div>
        <div className={"summary"}>
            <p className={"label"}>Celkem</p>
            <p className={"total"}>{formatCurrency(props.currencies[currency], cart.items.map(i => i.store?.price).filter(i => i !== undefined).map(i => (i as {
                [key: string]: number
            })[currency]).reduce((a, b) => a + b, 0))}</p>
        </div>
        <Button onClick={() => navigate("/store/checkout")}>Dokončit nákup</Button>
    </SideNavWidget>
} 