import Currency, {formatCurrency} from "../currency/Currency";
import "./StoreItemComponent.css";
import {GemPayment, StoreCategory, StoreItem, useCurrency} from "../../../app/store/store";
import Button from "../../../component/button/Button";
import {useAppSelector} from "../../../app/hooks";
import StoreItemModal from "./modal/StoreItemModal";
import {useState} from "react";

export default function StoreItemComponent(props: {
    item: StoreItem
    category: StoreCategory
    currencies: { [key: string]: Currency }
    setGemPayment: (v: GemPayment) => void
}) {
    const {item, category, currencies, setGemPayment} = props;
    const [modal, setModal] = useState(false);

    const currencyName = useAppSelector(useCurrency);

    const currency: Currency | undefined = currencies[currencyName];

    return <div className={"store-item"} key={item.id}>
        <div className={`img-container ${item.asset_style}`}>
<img src={item.asset} alt={""}/>
        </div>
        <h3>{item.name}</h3>
        {item.bonus ? <p className={"bonus"}>{item.bonus}</p> : null}
        <p className={"price"}>
            {formatCurrency(currency, item.price[currencyName])}{item.price["GEMS"] ?
            <span className={"gems"}>{formatCurrency(currencies["GEMS"], item.price["GEMS"])}</span> : null}
        </p>
        {item.duration ? <p className={"duration"}>Za {item.duration.value} {item.duration.unit}</p> : null}
        <div className={"bottom-container"}>
            <div className={"featured"}>
                <p className={"label"}>{category.featured_label}</p>
                {item.featured.map((featured, i) => <p className={"item"} key={i}>{featured}</p>)}
            </div>
            <Button onClick={() => setModal(true)}>Koupit</Button>
        </div>
        {modal ? <StoreItemModal item={item} category={category} currencies={currencies}
            close={() => setModal(false)} setGemPayment={setGemPayment}/> : null}
    </div>
}