import "./StoreView.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../component/footer/Footer";
import StoreNavbar from "./navbar/StoreNavbar";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GemPayment, StoreCategory, StoreItem, useCurrency} from "../../app/store/store";
import fetchWithRetry from "../../util/request";
import Currency from "./currency/Currency";
import {useAppSelector} from "../../app/hooks";
import StoreItemComponent from "./item/StoreItemComponent";
import GemPaymentModal from "./gem/GemPaymentModal";

interface StoreData {
    categories: StoreCategory[]
    currencies: { [key: string]: Currency }
    category?: {
        id: string
        name: string
        icon: string
        description?: string
        featured_label: string
        items: StoreItem[]
    }
}

export default function StoreView() {
    const navigate = useNavigate();
    let {category} = useParams();
    const currencyName = useAppSelector(useCurrency);
    const [gemPayment, setGemPayment] = useState<GemPayment>();

    const [data, setData] = useState<StoreData | null>(null)

    const currency: Currency | undefined = data?.currencies[currencyName];

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (res.status === 404) {
                navigate("/store")
                return true;
            }
            if (!res.ok) return false;
            let body = (await res.json()) as StoreData;
            setData(body as StoreData)
            return true;
        }, process.env.REACT_APP_API_BASE + `/store/category${category ? `/${category}` : ""}`);
    }, [category, navigate])

    return <div className={"store-view"}>
        <Navbar/>
        {!data || !currency ? <div className={"loader big center margin"}/> : <div className={"store"}>
            <StoreNavbar currency={"CZK"} currencies={data.currencies}
                         category={data.category?.id ?? (category as string ?? "")}
                         categories={data.categories}>
            </StoreNavbar>
            {!data.category ? <div className={"loader big center margin"}/> :
                <div className={"category"}>
                    <div className={"title"}>
                        <h1>{data.category?.name}</h1>
                        <p className={"description"}>{data.category?.description}</p>
                    </div>
                    <div className={"items"}>
                        {
                            data.category.items.map(item => <StoreItemComponent
                                item={item}
                                category={data.category!}
                                currencies={data.currencies}
                                key={item.id} setGemPayment={setGemPayment}/>)
                        }
                    </div>
                </div>
            }
            {!gemPayment ? null : <GemPaymentModal payment={gemPayment} close={() => setGemPayment(undefined)}
                                                   currencies={data.currencies}/>}
        </div>
        }
        <Footer/>
    </div>
}