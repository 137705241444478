import "./FlexBox.css"

export default function FlexBox(props: {
    direction?: "row" | "row-reverse" | "column" | "column-reverse"
    justifyContent?: "left" | "center" | "right" | "space-between" | "space-around"
    alignItems?: "flex-start" | "center" | "flex-end"
    gap?: number | string
    className?: string
    children?: any
}) {
    const { direction, justifyContent, alignItems, gap, className, children } = props;
    
    return <div style={{
        flexDirection: direction,
        justifyContent: justifyContent,
        alignItems: alignItems,
        gap: gap
    }} className={`flexbox${className ? ` ${className}` : ""}`}>{children}</div>
}