import * as icons from '@tabler/icons-react';

interface IconProps extends icons.TablerIconsProps {
    iconName: string;
}

export const Icon = ({iconName, ...props}: IconProps) => {
    // @ts-ignore
    const Icon = icons["Icon" + iconName] as () => JSX.Element;
    return <Icon {...props}/>;
}