import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../store";

export interface TokenData {
    uuid: string,
    name: string,
    premiumid: string,
    expires: number
}

interface AuthState {
    token?: string,
    user?: TokenData
}

function parseJWT(token: string) {
    const tokens = token.split(".");
    return JSON.parse(atob(tokens[1]));
}

function getJWTPayload(token: string): TokenData {
    const parsed = parseJWT(token);
    return {
        uuid: parsed.sub,
        name: parsed.name,
        premiumid: parsed.premid,
        expires: parsed.exp
    }
}

function createInitialState(): AuthState {
    const token = window.localStorage.getItem("pt-token");
    if (!token) return {};
    let user: undefined | TokenData = getJWTPayload(token);
    if (user && user.expires * 1000 <= Date.now()) {
        user = undefined;
        window.localStorage.removeItem("pt-token");
    }
    return {
        token: token,
        user: user
    }
}

const initialState: AuthState = createInitialState();

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<string>) => {
            const user = getJWTPayload(action.payload);
            if (user && user.expires * 1000 <= Date.now()) return;
            state.token = action.payload;
            state.user = user;
            window.localStorage.setItem("pt-token", action.payload)
        },
        logout: (state) => {
            state.token = undefined;
            state.user = undefined;
            window.localStorage.removeItem("pt-token")
        }
    },
})

export const {login, logout} = authSlice.actions

export const useAuth = (state: RootState) => state.auth

export default authSlice.reducer