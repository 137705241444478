import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useAuth} from "../../../app/auth/auth"
import {useAppSelector} from "../../../app/hooks"
import {GemPayment} from "../../../app/store/store"

import "./GemPaymentModal.css"
import fetchWithRetry from "../../../util/request";
import {IconCircleCheck, IconGift, IconX} from "@tabler/icons-react"
import Currency, {formatCurrency} from "../currency/Currency"
import Button from "../../../component/button/Button";

export default function GemPaymentModal(props: {
    payment: GemPayment
    currencies: { [key: string]: Currency }
    close: () => void
}) {
    const {payment, currencies, close} = props;

    const {item, gift} = payment;

    const auth = useAppSelector(useAuth)
    const [gemBalance, setGemBalance] = useState<number | undefined>(undefined)

    const [result, setResult] = useState<null | {
        ok: boolean,
        message?: string
    }>()
    const [sending, setSending] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.setProperty("overflow", null);
        }
    })

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (res.status === 401) navigate("/login");
            if (!res.ok) return false;
            let body = await res.text().then(parseInt);
            setGemBalance(body);
            return true;
        }, process.env.REACT_APP_API_BASE + `/store/gem/balance`, {
            headers: {
                Authorization: auth.token ?? ""
            }
        });
    }, [auth, setGemBalance, navigate, currencies])

    if (!auth.user) {
        navigate("/login");
        return null;
    }

    if (result?.ok) {
        return <div className={"gem-payment-modal-container"}>
            <IconX className={"close"} onClick={close}/>
            <div className={`gem-payment-modal${gemBalance ? "" : " loading"}`}>
                <img src={item.asset} alt={""}/>
                {gift ? <p className={"gift"}><IconGift/> Dárek pro <span>{gift.name}</span></p> : null}
                <h3>{item.name}</h3>
                <div className={"success spacer"}>
                    <p><IconCircleCheck size={32}/>Nákup byl zaplacen</p>
                    <Button onClick={close}>Zavřít</Button>
                </div>
            </div>
        </div>
    }

    return <div className={"gem-payment-modal-container"}>
        <IconX className={"close"} onClick={close}/>
        <div className={`gem-payment-modal${gemBalance ? "" : " loading"}`}>
            {
                gemBalance !== undefined ? <>
                    <img src={item.asset} alt={""}/>
                    {gift ? <p className={"gift"}><IconGift/> Dárek pro <span>{gift.name}</span></p> : null}
                    <h3>{item.name}</h3>
                    <p className={"price"}>{formatCurrency(currencies["GEMS"], item.price["GEMS"])}</p>
                    {gemBalance >= item.price["GEMS"] ? <>
                            <div className={"spacer"}>
                                {
                                    result?.ok === false ? <p className={"error"}>{result.message}</p> :
                                        <p className={"new-balance"}>Stav tvého účtu po nákupu
                                            <span>{
                                                formatCurrency(
                                                    currencies["GEMS"],
                                                    gemBalance - item.price["GEMS"]
                                                )
                                            }</span>
                                        </p>
                                }
                                <Button type={"green"} loading={sending} onClick={() => {
                                    if (sending) return;
                                    setSending(true);
                                    fetch(process.env.REACT_APP_API_BASE + `/store/gem/purchase`, {
                                        method: "POST",
                                        body: JSON.stringify({
                                            item: payment.item.id,
                                            category: payment.category,
                                            gift: payment.gift?.uuid
                                        }),
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": auth.token ?? ""
                                        }
                                    }).then(res => {
                                        if (res.ok) {
                                            setResult({
                                                ok: true
                                            })
                                        } else {
                                            res.json().then(body => {
                                                setResult({
                                                    ok: false,
                                                    message: body.message
                                                })
                                            })
                                        }
                                        setSending(false);
                                    }).catch(() => setSending(false))
                                }}>Potvrdit nákup</Button>
                            </div>
                        </> :
                        <div className={"spacer"}>
                            <p className={"status"}>Na tento nákup potřebuješ ještě {
                                formatCurrency(
                                    currencies["GEMS"],
                                    -gemBalance
                                    + item.price["GEMS"]
                                )
                            }</p>
                            <Button onClick={close}>Zavřít</Button>
                        </div>
                    }
                </> : <div className={"loader big"}/>
            }
        </div>
    </div>;
}