export default interface Currency {
    name: string
    symbol: string
    placement: "ahead" | "behind"
    gem_value: number
    multiplied: number
    invoice_required_amount?: number
}

function numberWithSapces(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatCurrency(currency: Currency, amount: any) {
    const realAmount = numberWithSapces(amount / currency.multiplied);
    if (currency.placement === "ahead") {
        return currency.symbol + realAmount;
    } else return realAmount + currency.symbol;
}