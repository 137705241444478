import "./LoginView.css"
import Navbar from "../../component/navbar/Navbar";
import Title from "../../component/title/Title";
import ViewTitle from "../../component/title/view/ViewTitle";
import Text from "../../component/text/Text";
import Footer from "../../component/footer/Footer";
import fetchWithRetry from "../../util/request";
import {useEffect, useMemo, useRef, useState} from "react";
import Button from "../../component/button/Button";
import {useDispatch} from "react-redux";
import {login, useAuth} from "../../app/auth/auth";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../app/hooks";

const Discord = require("../../asset/icons/discord.svg").ReactComponent;

export default function LoginView() {
    const query = useMemo(() => new URLSearchParams(window.location.search), []);
    const navigate = useNavigate();

    const user = useAppSelector(useAuth).user;
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) navigate(query.get("return") ?? "/")
    }, [user, query, navigate])

    const [discordAuthUrl, setDiscordAuthUrl] = useState<string | null>(null);
    const [discordLoginResult, setDiscordLoginResult] = useState<{
        error?: string
        ok?: boolean
    }>({});

    const nameRef = useRef<HTMLInputElement>(null);
    const passRef = useRef<HTMLInputElement>(null);

    const [loginRequest, setLoginRequest] = useState<{
        sending: boolean
        ok?: boolean
        errorMessage?: string
    }>({sending: false});

    function loginWithCredentials() {
        setLoginRequest({
            sending: true
        })
        fetch(process.env.REACT_APP_API_BASE + "/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: nameRef.current?.value,
                pass: passRef.current?.value
            })
        }).then(res => {
            if (!res.ok) {
                res.json().then(body => {
                    setLoginRequest({
                        sending: false,
                        ok: false,
                        errorMessage: body.message
                    })
                })
            } else {
                res.text().then(body => {
                    dispatch(login(body))
                })
            }
        }).catch(() => setLoginRequest({
            sending: false,
            ok: false,
            errorMessage: "Nastala neznámá chyba"
        }))
    }

    useEffect(() => {
        return fetchWithRetry(async res => {
            return res.text().then(body => {
                if (!res.ok) return false;
                setDiscordAuthUrl(body);
                return true;
            })
        }, process.env.REACT_APP_API_BASE + "/auth/discord/authurl", {
            headers: {
                "Return-Hostname": window.location.origin
            }
        })
    }, [])

    const discordReturnCode = query.get("code");

    useEffect(() => {
        if (!discordReturnCode) return;
        return fetchWithRetry(res => {
            if (res.status === 400) {
                res.json().then(body => setDiscordLoginResult({
                    error: body.message,
                    ok: false
                }));
                return true;
            } else if (res.ok) {
                res.text().then(body => {
                    dispatch(login(body))
                })
                return true;
            } else return false;
        }, process.env.REACT_APP_API_BASE + "/auth/login/discord", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Redirect-Uri": window.location.origin + window.location.pathname
            },
            body: discordReturnCode
        })
    }, [discordReturnCode, dispatch])

    if (discordReturnCode && !discordLoginResult.error) {
        return <div className={"loader big center margin"}/>;
    }

    return <div className={"login-view"}>
        <Navbar/>
        <ViewTitle>
            <Title>Přihlášení</Title>
            <Text>Přihlaš se do svého účtu</Text>
        </ViewTitle>
        <div className={"login-container small-shadow"}>
            <div className={"classic"}>
                <div className={"form"}>
                    <label htmlFor={"username"}>Nick</label>
                    <input type={"text"} id={"username"} ref={nameRef} onKeyDown={e => {
                        if (e.key === "Enter") loginWithCredentials();
                    }}/>

                    <label htmlFor={"password"}>Heslo</label>
                    <input type={"password"} id={"password"} ref={passRef} onKeyDown={e => {
                        if (e.key === "Enter") loginWithCredentials();
                    }}/>

                    {loginRequest.errorMessage ? <Text color={"red"}>{loginRequest.errorMessage}</Text> : null}
                </div>

                <Button loading={loginRequest.sending} onClick={() => loginWithCredentials()}>Přihlásit se</Button>
            </div>
            <div className={"splitter"}/>
            <div className={"discord"}>
                {discordLoginResult.error ? <Text color={"red"}>{discordLoginResult.error}</Text> :
                    <Text>Máš propojený účet s Discordem? Přihlaš se přes něj kliknutím na tlačítko níže</Text>}
                <Button placeholder={!discordAuthUrl} onClick={() => {
                    if (discordAuthUrl) window.location.href = discordAuthUrl;
                }}><Discord/>Přihlásit se s Discordem</Button>
            </div>
        </div>
        <Footer/>
    </div>
}