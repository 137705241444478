import "./Navbar.css"
import {useAppSelector} from "../../app/hooks";
import {DropDown, DropDownItem, DropDownParent} from "../dropdown/DropDown";
import {
    IconCaretDownFilled,
    IconLogout,
    IconMenu2,
    IconX
} from "@tabler/icons-react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import Button from "../button/Button";
import {logout, useAuth} from "../../app/auth/auth";
import {useRef, useState} from "react";
import useOutsideHandler from "../../util/OutsideHandler";
import {MENU_ITEMS} from "../../view/account/AccountView";
import {Icon} from "../icon/Icon";

export default function Navbar() {
    const [expanded, setExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useOutsideHandler(ref, () => setExpanded(false));

    const dispatch = useDispatch()
    const user = useAppSelector(useAuth).user;
    let userAction;


    if (user) {
        userAction = <DropDownParent>
            <div className={"profile"}>
                <img
                    src={"https://minotar.net/helm/" + (user.premiumid !== null ? user.premiumid : user.uuid) + "/100.png"}
                    alt={""}/>
                <span>{user.name}</span>
                <IconCaretDownFilled/>
                <DropDown direction={expanded ? "center" : "left"}>
                    {MENU_ITEMS.map(item => {
                        return <DropDownItem
                            key={`account/${item.id}`}
                            icon={<Icon iconName={item.icon}/>}
                            link={`/account/${item.id}`}>{item.name}</DropDownItem>
                    })}
                    {/*<DropDownItem icon={<IconTicket/>} link={"/tickets"}>Moje tickety</DropDownItem>*/}
                    <DropDownItem icon={<IconLogout/>} color={"red"}
                                  onClick={() => dispatch(logout())}>Odhlásit se</DropDownItem>
                </DropDown>
            </div>
        </DropDownParent>
    } else {
        userAction = <Link to={"/login"}><Button>Přihlásit se</Button></Link>
    }


    return <div ref={ref} className={`nav-container small-shadow${expanded ? " expanded" : ""}`}>
        <nav className={"full"}>
            <div className={"links"}>
                <img className={"logo"} src="/img/logo/small.webp" alt="Logo"/>
                <Link to={"/"}>Domů</Link>
                <Link to={"/store"}>Obchod</Link>
                {/*<Link to={"/announcements"}>Oznámení</Link>*/}
                {/*{user ? <>*/}
                {/*    <Link to={"/tickets"}>Tickety</Link>*/}
                {/*</> : null}*/}
            </div>
            {userAction}
        </nav>
        <nav className={"mobile"} onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains("close")) return;
            setExpanded(true)
        }}>
            <img className={"logo"} src="/img/logo/small.webp" alt="Logo"/>
            {expanded ? <IconX className={"close"} onClick={() => setExpanded(false)}/> : <IconMenu2/>}
        </nav>
    </div>
}