import "./AccountView.css";
import Footer from "../../component/footer/Footer";
import Navbar from "../../component/navbar/Navbar";
import React, {useEffect} from "react";
import SideNavBar from "../../component/sidenavbar/SideNavbar";
import {useNavigate, useParams} from "react-router-dom";
import PaymentList from "./payments/PaymentList";
import NotFoundView from "../notfound/NotFoundView";
import {TokenData, useAuth} from "../../app/auth/auth";
import {useAppSelector} from "../../app/hooks";
import DiscordConnection from "./discord/DiscordConnection";

export const MENU_ITEMS: {
    component: (token: string, user: TokenData, url: string) => React.JSX.Element;
    name: string;
    icon: string;
    id: string
}[] = [
    {
        id: "discord",
        name: "Propojení s Discordem",
        icon: "ArrowsRightLeft",
        component: (token: string, user: TokenData, url: string) => <DiscordConnection token={token} user={user}
                                                                                       url={url}/>
    },
    {
        id: "payments",
        name: "Historie plateb",
        icon: "Coins",
        component: (token: string) => <PaymentList token={token}/>
    }
]

export default function AccountView() {
    let {section} = useParams()
    const auth = useAppSelector(useAuth)
    const navigate = useNavigate()

    const item = MENU_ITEMS.find(v => v.id.toLowerCase() === section?.toLowerCase());

    useEffect(() => {
        if (!item) return;
        if (!auth.user || !auth.token)
            navigate("/login?return=/account/" + section);
    }, [auth, item, navigate, section])

    if (!item) return <NotFoundView/>;

    if (!auth.user || !auth.token)
        return null;

    const content = item.component(auth.token, auth.user, "/account/" + item.id);

    return <div className={"account-view"}>
        <Navbar/>
        <div className={"account-view-content"}>
            <SideNavBar className={"account-navbar"} title={<>Účet a nastavení</>}
                        entries={MENU_ITEMS.map(c => {
                            return {
                                icon: c.icon,
                                name: c.name,
                                link: `/account/${c.id}`,
                                selected: c.id.toLowerCase() === section?.toLowerCase()
                            }
                        })}>
            </SideNavBar>
            {content}
        </div>
        <Footer/>
    </div>
}
