import "./DropDown.css"
import {createContext, createRef, MouseEventHandler, useContext, useState} from "react";
import useOutsideHandler from "../../util/OutsideHandler";
import {Link} from "react-router-dom";

const TextContext = createContext<{
    expanded?: boolean
    forceClose: () => void
} | null>(null);

export function DropDownParent(props: {
    classList?: string
    children?: any
}) {
    const { classList, children } = props;

    const [expanded, setExpanded] = useState(false);
    const ref = createRef<HTMLDivElement>()

    useOutsideHandler(ref, () => setExpanded(false));

    return <div ref={ref} className={`dropdown-parent${classList ? ` ${classList}` : ""}`} onClick={() => setExpanded(!expanded)}>
        <TextContext.Provider value={{
            expanded: expanded,
            forceClose: () => setExpanded(false)
        }}>
            {children}
        </TextContext.Provider>
    </div>
}

export function DropDownItem(props: {
    icon: any
    color?: "red" | "green"
    link?: string
    onClick?: MouseEventHandler
    children?: any
}) {
    const { icon, color, link, onClick, children } = props;

    const content = <div className={`item${color ? ` ${color}` : ""}`} onClick={onClick}>{icon}{children}</div>;
    
    if (link) return <Link to={link}>{content}</Link>
    
    return content;
}

export function DropDown(props: {
    direction?: "right" | "left" | "center"
    children?: any
}) {
    const { direction, children } = props;
    
    const context = useContext(TextContext);
    if (!context) return null;

    return <div className={`dropdown ${direction ?? "right"} ${context.expanded ? "expanded" : "closed"}`}>
        {children}
    </div>
}