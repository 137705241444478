import "./Title.css"

export default function Title(props: {
    bold?: boolean
    main?: boolean
    children?: any
}) {
    const { bold, main, children } = props;
    
    if (main) return <h1 className={`title${bold ? " bold" : ""}`}>{children}</h1>
    
    return <h2 className={`title ${bold ? " bold" : ""}`}>{children}</h2>
}